import { createSlice } from "@reduxjs/toolkit";
import { callTypes } from "../../../../redux/rootReducer";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  searchItems: null,
  data: undefined,
  error: null,
  listItems: null,
  assignments: undefined,
  successfulMessage:false,
};

export const rolesSlice = createSlice({
  name: "Roles",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.successfulMessage=false;
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    startCall: (state, action) => {
      // state.successfulMessage=false;
      state.data = undefined;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getItemById
    itemFetched: (state, action) => {
      state.actionsLoading = false;
      state.data = action.payload.data;
      state.error = null;
      state.successfulMessage=false;
    },
    // findItems
    itemsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findSelectItems
    selectItemsFetched: (state, action) => {
      const { searchItems } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.searchItems = searchItems;
      state.successfulMessage=false;
    },
    // createItem
    itemCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
      state.successfulMessage=true;
    },
    itemCloned: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
      state.successfulMessage=false;
    },
    // updateItem
    itemUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.successfulMessage=true;
    },
    // deleteItem
    itemDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.successfulMessage=false;
    },
    // deleteRoles
    itemsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.successfulMessage=false;
    },
    // rolesUpdateState
    itemsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    // findItems
    getItemsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.listItems = action.payload.data;
    },
    assignmentSaved: (state) => {
      state.actionsLoading = false;
      state.error = null;
    },
    assignmentFetched: (state, action) => {
      state.actionsLoading = false;
      state.assignments = action.payload.data;
      state.error = null;
    },
  },
});
