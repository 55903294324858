import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { rolesSlice } from "../app/modules/Roles/_redux/slice";
import { userRolesSlice } from "../app/modules/UserRoles/_redux/slice";
import { permissionsSlice } from "../app/modules/Permissions/_redux/slice";
import { permissionTypesSlice } from "../app/modules/PermissionTypes/_redux/slice";
import { permissionLevelsSlice } from "../app/modules/PermissionLevels/_redux/slice";
import { modulesSlice } from "../app/modules/Modules/_redux/slice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  roles: rolesSlice.reducer,
  userRoles: userRolesSlice.reducer,
  permissions: permissionsSlice.reducer,
  permissionTypes: permissionTypesSlice.reducer,
  permissionLevels: permissionLevelsSlice.reducer,
  modules: modulesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}

export const handleError = (
  dispatch,
  actions,
  callType,
  error,
  defaultMessage = "Something went wrong"
) => {
  const errorMessage = error ? error.message : defaultMessage;

  dispatch(
    actions.catchError({
      error: errorMessage,
      callType,
    })
  );

  setTimeout(() => {
    dispatch(actions.clearError());
  }, 10000);

  return Promise.reject(errorMessage);
};

export const callTypes = {
  list: "list",
  action: "action",
};
