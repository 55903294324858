import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { userRoles } from "../../../../_component/constant";
import {
  getUserByToken,
  getInstituteBranches,
  getUserPermissions,
  getInstitutes,
  getBranches,
  setInstituteBranch,
  getBranchesByInstituteId,
} from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  InstituteBranchesRequested: "[Request Institutes Branches] Action",
  InstituteBranchesLoaded: "[Load Institute Branches] API",
  InstitutesRequested: "[Request Institutes] Action",
  InstitutesLoaded: "[Load Institutes] API",
  BranchesRequested: "[Request Branches] Action",
  BranchesLoaded: "[Load Branches] API",
  SetInstituteBranchRequested: "[Request Set Institute Branch] Action",
  SetInstituteBranchLoaded: "[Load Set Institute Branch] API",
  UserPermissionsRequested: "[Request User Permissions] Action",
  UserPermissionsLoaded: "[Load User Permissions] API",
  BranchesByInstituteIdRequested: "[Request Branches By Institute Id] Action",
  BranchesByInstituteIdLoaded: "[Load Branches By Institute Id] API",
  SetLoading: "[Set Loading] Action",
};

const initialAuthState = {
  user: null,
  institutes: [],
  instituteBranches: [],
  branches: [],
  instituteId: null,
  branchId: null,
  displayName: null,
  userRoles: [],
  permissions: null,
  isLoading: false,
};

export const reducer = persistReducer(
  {
    storage,
    key: "v726-demo1-auth",
    whitelist: [],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { accessToken, refreshToken, referralToken } = action.payload;

        localStorage.setItem(
          "authToken",
          JSON.stringify({ accessToken, refreshToken, referralToken })
        );

        return state;
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        localStorage.removeItem("authToken");
        return { ...state, ...initialAuthState };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.InstitutesLoaded: {
        const { institutes } = action.payload;

        return { ...state, institutes };
      }

      case actionTypes.InstituteBranchesLoaded: {
        const { instituteBranches } = action.payload;

        return { ...state, instituteBranches };
      }

      case actionTypes.BranchesLoaded: {
        const { branches } = action.payload;
        return { ...state, branches };
      }

      case actionTypes.SetInstituteBranchLoaded: {
        const { data } = action.payload;

        if (data) {
          const {
            accessToken,
            refreshToken,
            referralToken,
            instituteId,
            branchId,
            displayName,
            userRoles,
            permissions,
          } = data;

          localStorage.setItem(
            "authToken",
            JSON.stringify({ accessToken, refreshToken, referralToken })
          );

          return {
            ...state,
            instituteId,
            branchId,
            displayName,
            userRoles,
            permissions,
            isLoading: false,
          };
        } else {
          localStorage.removeItem("authToken");

          return { ...state, displayName: null };
        }
      }

      case actionTypes.UserPermissionsLoaded: {
        const { userRoles, permissions } = action.payload;

        return { ...state, userRoles, permissions };
      }

      case actionTypes.BranchesByInstituteIdLoaded: {
        const { branches } = action.payload;
        return { ...state, branches };
      }

      case actionTypes.SetLoading: {
        return { ...state, isLoading: true };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (accessToken, refreshToken, referralToken) => ({
    type: actionTypes.Login,
    payload: { accessToken, refreshToken, referralToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  requestInstitutes: () => ({
    type: actionTypes.InstitutesRequested,
    payload: {},
  }),
  fulfillInstitutes: (institutes) => ({
    type: actionTypes.InstitutesLoaded,
    payload: { institutes },
  }),
  requestInstituteBranches: () => ({
    type: actionTypes.InstituteBranchesRequested,
    payload: {},
  }),
  fulfillInstituteBranches: (instituteBranches) => ({
    type: actionTypes.InstituteBranchesLoaded,
    payload: { instituteBranches },
  }),
  requestBranches: () => ({
    type: actionTypes.BranchesRequested,
    payload: {},
  }),
  fulfillBranches: (branches) => ({
    type: actionTypes.BranchesLoaded,
    payload: { branches },
  }),
  requestSetInstituteBranch: ({ instituteId, branchId, displayName }) => ({
    type: actionTypes.SetInstituteBranchRequested,
    payload: { instituteId, branchId, displayName },
  }),
  fulfillSetInstituteBranch: (data) => ({
    type: actionTypes.SetInstituteBranchLoaded,
    payload: { data },
  }),
  requestUserPermissions: () => ({
    type: actionTypes.UserPermissionsRequested,
    payload: {},
  }),
  fulfillUserPermissions: (userRoles, permissions) => ({
    type: actionTypes.UserPermissionsLoaded,
    payload: { userRoles, permissions },
  }),
  requestBranchesByInstituteId: (instituteId) => ({
    type: actionTypes.BranchesByInstituteIdRequested,
    payload: { instituteId },
  }),
  fulfillBranchesByInstituteId: (branches) => ({
    type: actionTypes.BranchesByInstituteIdLoaded,
    payload: { branches },
  }),
  fulfillSetLoading: () => ({
    type: actionTypes.SetLoading,
    payload: {},
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    if (user && user.rouvrDefaultRole !== userRoles.superAdmin) {
      const { data: institutes } = yield getInstitutes();
      // const { data: branches } = yield getBranches();

      yield put(actions.fulfillInstitutes(institutes));
      // yield put(actions.fulfillBranches(branches));

      // if (branches && branches.length > 0) {
      const { data } = yield setInstituteBranch({
        moduleName: process.env.REACT_APP_MODULE_NAME,
        instituteId: institutes[0].id,
        branchId: "",
      });

      yield put(
        actions.fulfillSetInstituteBranch({
          ...data,
          instituteId: institutes[0].id,
          branchId: "",
          displayName: institutes[0].name,
        })
      );

      yield put(
        actions.requestBranches({
          ...data,
          displayName: institutes[0].name,
        })
      );
      // }

      // yield put(actions.requestUserPermissions());
    }

    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(
    actionTypes.InstitutesRequested,
    function* institutesRequested() {
      const { data: institutes } = yield getInstitutes();

      yield put(actions.fulfillInstitutes(institutes));
    }
  );

  yield takeLatest(
    actionTypes.InstituteBranchesRequested,
    function* instituteBranchesRequested() {
      const { data: instituteBranches } = yield getInstituteBranches();

      yield put(actions.fulfillInstituteBranches(instituteBranches));
    }
  );

  yield takeLatest(
    actionTypes.BranchesRequested,
    function* branchesRequested() {
      const { data: branches } = yield getBranches();

      yield put(actions.fulfillBranches(branches));
    }
  );

  yield takeLatest(
    actionTypes.SetInstituteBranchRequested,
    function* setInstituteBranchRequested({ payload }) {
      yield put(actions.fulfillSetLoading());
  
      let { instituteId, branchId, displayName } = payload;
  
      let response = yield setInstituteBranch({
        moduleName: process.env.REACT_APP_MODULE_NAME,
        instituteId,
        branchId,
      });
  console.log('instituteId !== "" && branchId !== "" && data && data.permissions && data.permissions.length === 0 =>',instituteId !== "" , branchId !== "" , response.data , response.data.permissions , response.data.permissions.length === 0);
      if(instituteId !== "" && branchId !== "" && response.data && response.data.permissions && response.data.permissions.length === 0) {
        branchId = "";
        instituteId = "";
        response = yield setInstituteBranch({
          moduleName: process.env.REACT_APP_MODULE_NAME,
          instituteId,
          branchId,
        });
      }
  
      const data = response.data;
  
      yield put(
        actions.fulfillSetInstituteBranch({
          ...data,
          instituteId,
          branchId,
          displayName,
        })
      );
    }
  );

  yield takeLatest(
    actionTypes.UserPermissionsRequested,
    function* userPermissionsRequested() {
      const {
        data: { userRoles, permissions },
      } = yield getUserPermissions();

      yield put(actions.fulfillUserPermissions(userRoles, permissions));
    }
  );

  yield takeLatest(
    actionTypes.BranchesByInstituteIdRequested,
    function* branchesByInstituteIdRequested({ payload }) {
      yield put(actions.fulfillBranches([]));

      const { data: branches } = yield getBranchesByInstituteId(
        payload.instituteId
      );

      yield put(actions.fulfillBranchesByInstituteId(branches));
    }
  );
}
