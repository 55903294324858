import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

const RolesPage = lazy(() => import("./modules/Roles/pages/index"));
const ModulesPage = lazy(() => import("./modules/Modules/pages/index"));
const PermissionTypesPage = lazy(() =>
  import("./modules/PermissionTypes/pages/index")
);
const PermissionLevelsPage = lazy(() =>
  import("./modules/PermissionLevels/pages/index")
);
const PermissionsPage = lazy(() => import("./modules/Permissions/pages/index"));
const UserRolesPage = lazy(() => import("./modules/UserRoles/pages/index"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/roles" />

        <ContentRoute path="/roles" component={RolesPage} />
        <ContentRoute path="/modules" component={ModulesPage} />
        <ContentRoute
          path="/permission-types"
          component={PermissionTypesPage}
        />
        <ContentRoute
          path="/permission-levels"
          component={PermissionLevelsPage}
        />
        <ContentRoute path="/permissions" component={PermissionsPage} />
        <ContentRoute path="/user-roles" component={UserRolesPage} />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
